<template>
  <div class="section container">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li class="is-active">
          <a>Administração</a>
        </li>
        <li class="is-active">
          <a>Fale conosco</a>
        </li>
      </ul>
    </nav>
    
    <b-collapse class="card m-b-md" :open="false" aria-id="filterContent">
      <div
        slot="trigger"
        slot-scope="props"
        class="card-header"
        role="button"
        aria-controls="filterContent"
      >
        <p class="card-header-title">Filtro</p>
        <a class="card-header-icon">
          <b-icon :icon="props.open ? 'caret-down' : 'caret-up'"></b-icon>
        </a>
      </div>
      <div class="card-content">
        <form @submit.prevent="getQuest">
          <b-field label="Data de Inicio">
            <b-datepicker
              :month-names="datePicker[0]"
              :day-names="datePicker[1]"
              v-model="query.initDate"
              icon="calendar-alt"
            ></b-datepicker>
          </b-field>

          <b-field label="Data de Fim">
            <b-datepicker
              :month-names="datePicker[0]"
              :day-names="datePicker[1]"
              v-model="query.endtDate"
              icon="calendar-alt"
            ></b-datepicker>
          </b-field>

          <!-- <b-field label="Grupo">
            <b-select v-model="query.team" placeholder="Grupo">
              <option v-for="team in teams" :key="team._id" :value="team._id">{{team.name}}</option>
              <option :value="null">Nenhum</option>
            </b-select>
          </b-field>-->

          <!-- <b-field label="Curso">
            <b-select v-model="query.curso" placeholder="Curso">
              <option
                v-for="course in courses"
                :key="course._id"
                :value="course._id"
              >{{course.name}}</option>
              <option :value="null">Nenhum</option>
            </b-select>
          </b-field>-->

          <b-field label="Usuário">
            <Multiselect
              v-model="users"
              id="ajax"
              label="name"
              track-by="name"
              placeholder="Digite para pesquisar"
              open-direction="bottom"
              :options="searchList"
              :multiple="false"
              :searchable="true"
              :loading="isLoadingMultiSelect"
              :internal-search="false"
              :clear-on-select="false"
              :close-on-select="true"
              :options-limit="300"
              :limit="3"
              :max-height="600"
              :show-no-results="false"
              :hide-selected="true"
              :preselect-first="false"
              @search-change="getBySearch"
              selectLabel="Pressione enter para selecionar"
              deselectLabel="Pressione enter para desmarcar"
              selectedLabel="Selecionado"
            >
              <template slot="noOptions">Lista vazia</template>
            </Multiselect>
          </b-field>

          <button class="button is-primary">Buscar</button>
        </form>
      </div>
    </b-collapse>

    <h1>Total: {{total}}</h1>

    <b-table
      :data="data"
      :paginated="false"
      current-page.sync="1"
      :pagination-simple="false"
      default-sort-direction="asc"
      detailed
      :opened-detailed="[1]"
      detail-key="_id"
      :show-detail-icon="true"
    >

      <template slot-scope="props">
        <b-table-column field="name" label="Nome" sortable>{{ props.row.name }}</b-table-column>
        
        <b-table-column field="email" label="Email" sortable>{{ props.row.email }}</b-table-column>

        <b-table-column field="callNumber" label="Número do Chamado" sortable>{{ props.row.callNumber }}</b-table-column>

        <b-table-column field="status" label="Status" sortable>{{ props.row.status }}</b-table-column>

        <b-table-column field="createdAt" label="Data do Envio" sortable>{{ new Date(props.row.createdAt).toLocaleDateString("pt-BR") }}</b-table-column>
      </template>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <b-button
                class="m-r-sm"
                type="is-primary"
                @click="getQuestById(props.row._id, props)"
              >Editar</b-button>

              <b-button type="is-danger" @click="confirm(props)">Remover</b-button>
            </div>
          </div>
        </article>
      </template>
    </b-table>

    <div class="pagination-custom">
      <b-pagination
        class="m-t-md"
        :total="total"
        :current.sync="current"
        :simple="false"
        :rounded="false"
        :per-page="perPage"
        aria-next-label="Próxima pagina"
        aria-previous-label="Pagina anterior"
        aria-page-label="Page"
        aria-current-label="Pagina atual"
      ></b-pagination>
    </div>

    <Modal
      :inputs="editModule"
      :active="showModal2"
      :clear="clear"
      @functionByModal="update"
      @close="showModal2 = false"
    ></Modal>
  </div>
</template>

<script>
import Axios from "axios";
import { debounce } from "lodash";

import Modal from "@/components/widgets/Modal";
import Multiselect from "vue-multiselect";
export default {
  components: { Modal, Multiselect },
  data() {
    return {
      showModal: false,
      showModal2: false,
      clear: false,
      datePicker: [
        [
          "Janeiro",
          "Fevereiro",
          "Março",
          "Abril",
          "Maio",
          "Junho",
          "Julho",
          "Agosto",
          "Setembro",
          "Outubro",
          "Novembro",
          "Dezembro"
        ],
        ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"]
      ],
      inputs: [],
      data: [],
      editModule: [],
      content: [],
      searchList: [],
      total: "",
      current: 1,
      perPage: "",
      users: [],
      query: {},
      isLoadingMultiSelect: null,
      search: null
    };
  },
  methods: {
    getBySearch: debounce(function() {
      this.isLoadingMultiSelect = true;
      if (arguments[0]) {
        Axios.get(`/v1/user/name/${arguments[0]}`)
          .then(res => {
            this.searchList = res.data;
            this.isLoadingMultiSelect = false;
          })
          .catch(erro => {
            this.isLoadingMultiSelect = false;
            console.error(erro);
          });
      }
      this.isLoadingMultiSelect = false;
    }, 500),
    edit(data) {
      this.editModule = [
        {
          label: "Mensagem",
          type: "textarea",
          model: "message",
          message: data.row.message,
          _id: data.row._id,
          course: data.row.course,
          user: data.row.user
        },
        {
          label: "Resposta",
          type: "textarea",
          model: "messageResponse",
          messageResponse: ""
        },
        {
          label: "Histórico de Respostas",
          type: "box",
          content: this.content
        },
        {
          label: "Categoria",
          type: "select",
          model: "category",
          category: data.row.category,
          content: [
            { _id: "Dúvida", name: "Dúvida" },
            { _id: "Sugestão", name: "Sugestão" },
            { _id: "Erro", name: "Erro" }
          ]
        },
        {
          label: "Status",
          type: "select",
          model: "status",
          status: data.row.status,
          content: [
            { _id: "Aberto", name: "Aberto" },
            { _id: "Em andamento", name: "Em andamento" },
            { _id: "Fechado", name: "Fechado" }
          ]
        },
        {
          label: "Nome",
          type: "text",
          model: "name",
          name: data.row.name
        }
      ];
      this.showModal2 = true;
    },
    update(data) {
      Axios.put(`/v1/contacts/${this.editModule[0]._id}`, {
        status: data.status
      });
      if (data.messageResponse) {
        Axios.post(`/v1/contact-answers/`, {
          contact: this.editModule[0]._id,
          message: data.messageResponse,
          course: this.editModule[0].course,
          user: this.editModule[0].user
        })
          .then(data => {
            if (data.data) {
              this.getQuest();
              this.showModal2 = false;
              this.$buefy.notification.open({
                message: "Dados atualizados com sucesso!",
                type: "is-success"
              });
            }
          })
          .catch(erro => {
            console.error(erro);
          });
      } else {
        this.getQuest();
        this.showModal2 = false;
        this.$buefy.notification.open({
          message: "Dados atualizados com sucesso!",
          type: "is-success"
        });
      }
    },
    remove(data) {
      Axios.delete(`/v1/contacts/${data.row._id}`)
        .then(data => {
          if (data.data) {
            this.getQuest();
            this.$buefy.notification.open({
              message: "Mesagem excluida com sucesso!",
              type: "is-success"
            });
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    getQuest() {
      let sunrise, sunset;
      if (this.query.initDate) {
        sunrise = new Date(this.query.initDate);
        sunrise.setUTCHours(0);
        sunrise.setUTCMinutes(0);
        sunrise.setUTCSeconds(0);
        sunrise.toISOString();
      }
      if (this.query.endtDate) {
        sunset = new Date(this.query.endtDate);
        sunset.setUTCHours(23);
        sunset.setUTCMinutes(59);
        sunset.setUTCSeconds(59);
        sunset.toISOString();
      }

      Axios.get(
        `/v1/contacts?page=${this.current}&user=${
          this.users._id ? this.users._id : "_null"
        }&team=${this.query.team ? this.query.team : "_null"}&course=${
          this.query.curso ? this.query.curso : "_null"
        }${sunrise ? "&createdAt[$gte]=" + sunrise : ""}${
          sunset ? "&createdAt[$lte]=" + sunset : ""
        }`
      )
        .then(data => {
          if (data.data) {
            this.data = data.data.data;
            this.total = data.data.total;
            this.perPage = data.data.limit;
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    getQuestById(data, props) {
      Axios.get(`/v1/contact-answers?contact=${data}`)
        .then(data => {
          if (data.data) {
            this.content = data.data.data;
            this.edit(props);
          }
        })
        .catch(erro => {
          console.error(erro);
          this.content = [];
          this.edit(props);
        });
    },
    confirm(data) {
      this.$buefy.dialog.confirm({
        message: "Tem certeza que deseja <b>excluir</b> este instrutor?",
        cancelText: "Cancelar",
        confirmText: "Confirmar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.remove(data)
      });
    }
  },
  watch: {
    current(current) {
      this.getQuest(current);
    }
  },
  mounted() {
    this.getQuest();
  }
};
</script>

<style>
</style>
